import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { format } from 'date-fns'

import Header from "../Include/Header";
import axios from "axios";

import welcome from '../Images/welcome-vector.png'
import custom from '../Images/customer-img.png'

function Notifications() {
    const [count, setcount] = useState(0);
    const [newpro, setNewpro] = useState(true);
    const [add_p, setAdd] = useState(2);
    const [property, setProperty] = useState([]);
    const [request, setRequest] = useState([]);
    
    const user = JSON.parse(localStorage.getItem("userData"));

    function is_read(is, id){
      let result = axios
          .get("https://demo-appprojects.com/sigma-bank/api/is-read-prop",{
          params: {
              status: is,
              pro_id : id
            }
          })
          .then((response) => {
            if (response.data.status === 200) {
              setcount(response.data.count)
              setProperty(response.data.properties);
            }
            setNewpro(false);
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(result)
    }
    
    useEffect(() => {
        if (newpro === true) {
          let cont = 0
          let result = axios
            .get("https://demo-appprojects.com/sigma-bank/api/get-notification",{
            params: {
                user_id: user.id,
              }
            })
            .then((response) => {
              if (response.data.status === 200) {
                // setcount(response.data.count)
                cont = response.data.count;
                setcount(cont)
                setProperty(response.data.properties);
              }
              setNewpro(false);
            })
            .catch((error) => {
              console.log(error);
            });
          console.log(result)

          let res = axios
            .get("https://demo-appprojects.com/sigma-bank/api/get-request", {
              params: {
                user_id: user.id,
                note: 1,
              },
            })
            .then((response) => {
              if (response.data.status === 200) {
                setRequest(response.data.request);
                cont += response.data.count
                
                setcount(cont)
              }
            })
            .catch((error) => {
              console.log(error);
            });
            console.log(res);
        }
      });

      const history = useNavigate();
      function propertyDetail(ev) {
        let title = ev.target.dataset.value;
        localStorage.setItem("getProperty", title);
        history("/property");
      }
      function requestDetail(ev) {
        let title = ev.target.dataset.value;
        localStorage.setItem("getProperty", title);
        if(user.role_id===3){
            localStorage.setItem("getseller", ev.target.attributes[2].value);
            history("/my-documents");    
        }else{
            history("/property");
        }
      }

    return (
        <>
            <main className="main-sectionWrapper">
                <div id="main-section" className="primary-scroll">
                    <Header />
                    
                    <img src={welcome} alt='img-here' className='imgFluid bottom-bg' loading='lazy' />

                    <div className="page-header">
                        <div className="page-header__title">
                            <Link to="/welcome" ><i className='bx bx-chevron-left'></i></Link>Notifications
                        </div>
                        {user.id===3&&
                        <Link href="#" className="notification-bell"><i className='bx bxs-bell bx-tada'></i>
                            <span className="total">{count}</span>
                        </Link>
                        }
                    </div>
                    <div className="btnWrapper" >
                      <Link className={add_p===1?'themeBtn active':'themeBtn'} onClick={()=>setAdd(1)}>Seller Request</Link>
                      <Link className={add_p===2?'themeBtn active':'themeBtn'} onClick={()=>setAdd(2)}>Buyer Requests</Link>
                    </div>

                    <div className='notification'>
                        <div className='container'>
                            <ul className="customer">
                              {add_p===1?
                                property.map((item, index) => (
                                  (user.id===3||item.user_id===user.id)&&
                                  <li className="customer-single" key={index} >
                                    
                                    <div className="customer-single__details ">
                                        <div>
                                            <img src={item.image?"https://demo-appprojects.com/sigma-bank/public/" + item.image:custom} alt='img-here' className='imgFluid' loading='lazy' data-value={item.id} onClick={propertyDetail} />
                                        </div>
                                        <div className="info" data-value={item.id} onClick={propertyDetail}>
                                            <div className="name">{item.title}</div>
                                            <span>{item.fullname} </span>
                                            <br />
                                            <span>{format(new Date(item.created_at), "MMMM do, yyyy H:mma")}</span>
                                        </div>
                                    </div>
                                    {user.id===3&&
                                    <div className="customer-single__status">
                                        {/* <span onClick={() => approved_prop(1, item.id)} className={item.is_active===1 && 'active'}> Approved</span> */}
                                        {/* <span onClick={() => approved_prop(0, item.id)} className={item.is_active===0 && 'active'}> Pending</span> */}
                                        <span onClick={() => is_read(1, item.id)} className={item.is_read===1 && 'active'}> Is Read</span>
                                        <span onClick={() => is_read(0, item.id)} className={item.is_read===0 && 'active'}> Unread</span>
                                    </div>
                                    }
                                    
                                </li>
                                ))
                                :
                                request.map((item, index) => (
                                  (user.id===3||item.user_id===user.id)&&
                                  <li className="customer-single" key={index} >
                                    
                                    <div className="customer-single__details ">
                                        <div>
                                            <img src={item.image?"https://demo-appprojects.com/sigma-bank/public/" + item.image:custom} alt='img-here' data-id={item.user_id} className='imgFluid' loading='lazy' data-value={item.id} onClick={requestDetail} />
                                        </div>
                                        <div className="info" data-value={item.id} onClick={requestDetail}>
                                            <div className="name">{item.title}</div>
                                            <span>{item.fullname} </span>
                                            <br />
                                            <span>{format(new Date(item.created_at), "MMMM do, yyyy H:mma")}</span>
                                        </div>
                                        <p>
                                        {item.type===0&&' Awaring for Approval'}
                                        {item.type===1&&'Approved'}
                                        {item.type===2&&'Rejected'}
                                        {item.type===3&&'Pending'}
                                        </p>
                                    </div>
                                    {user.id===3&&
                                    <div className="uptdated">
                                       <div className="uptdated__price">${item.price}</div>
                                    </div>
                                    }
                                    
                                </li>
                                ))
                                  }
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Notifications;