import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "../Include/Header";
import Carousel from "react-bootstrap/Carousel";

import property from '../Images/No-Image-Placeholder.svg.png'
import person from '../Images/person-img-1.png'

function Property() {
  const history = useNavigate();
  const [prop, setGetProp] = React.useState(localStorage.getItem("getProperty"));
  const [getPro, setGetPro] = React.useState([]);
  // const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("userData")));
  const user = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let title = localStorage.getItem("getProperty");
    if(getPro == ''){
      let result = axios
      .get("https://demo-appprojects.com/sigma-bank/api/get-property", {
        params: {
          title: title,
          user_id: user.id,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setGetPro(response.data.properties);
        } else {
          setGetProp(prop)
        }
      })
      .catch((error) => {
        console.log(error);
      });
      console.log(result)
    }

  });

  function deleteProperty(){
    
      let result = axios
      .get("https://demo-appprojects.com/sigma-bank/api/delete-property", {
        params: {
          title: prop,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          history('/all-properties');
        } 
      })
      .catch((error) => {
        console.log(error);
      });
      console.log(result)
  }
  console.log(getPro)
  
  return (
    <>
      <main className="main-sectionWrapper">
        <div id="main-section" className="primary-scroll">
          <Header />
          <div className="page-header">
            <div className="page-header__title">
              <Link to="/all-properties">
                <i className="bx bx-chevron-left"></i>
              </Link>
              All Property
            </div>
            {(user.role_id === 1 || user.role_id === "1") &&
            <div className="page-header__icons">
              <Link to='/edit-property' className="icon">
                <i className="bx bxs-pencil"></i>
              </Link>
              <Link onClick={() => { if (window.confirm('Are you sure you wish to delete this Property?')) deleteProperty() } } className="icon">
                <i className="bx bxs-trash-alt"></i>
              </Link>
            </div>
            }
          </div>

          <div className="property">
            <div className="container">
              <div className="property-card">
                <div className="propertyImgSlider">
                  {getPro.image ? (
                    <div className="property-card__img">
                      <img
                        src={"https://demo-appprojects.com/sigma-bank/public/" + getPro.image}
                        alt="img-here"
                        className="imgFluid"
                      />
                    </div>
                  ) : (
                    <Carousel nextLabel="" prevLabel="" indicators="">
                      <Carousel.Item interval={2000}>
                        <div className="property-card__img">
                          <img
                            src={property}
                            alt="img-here"
                            className="imgFluid"
                          />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item interval={2000}>
                        <div className="property-card__img">
                          <img
                            src={property}
                            alt="img-here"
                            className="imgFluid"
                          />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item interval={2000}>
                        <div className="property-card__img">
                          <img
                            src={property}
                            alt="img-here"
                            className="imgFluid"
                          />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  )}
                  </div>
                <div className="property-card__content">
                  {getPro.title}
                  <div className="wrapper">
                    <div className="reviews">
                      <i className="bx bxs-star"></i>
                      {getPro.rating} ({getPro.reviews} reviews)
                    </div>
                    <div className="address">
                      <i className="bx bxs-map"></i>
                      {getPro.location}
                    </div>
                  </div>
                </div>
              </div>
              <div className="owner">
                <div className="owner-details">
                  <div className="wrapper">
                    <div className="owner-details__img">
                      <img
                        src={person}
                        alt="img-here"
                        className="imgFluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="owner-details__info">
                      <div className="name">{getPro.fullname}</div>
                      {/* <p>Property owner</p> */}
                      <p>
                        {getPro.is_active===0&&'Awating for Approval'}
                        {getPro.is_active===1&&'Approved Property'}
                        {getPro.is_active===2&&'Rejected Property'}
                      </p>
                    </div>
                  </div>
                  <ul className="owner-details__contacts">
                    <li>
                      <Link href="#">
                        <i className="bx bxs-message-minus"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={"tel:+" + getPro.contact}>
                        <i className="bx bxs-phone bx-tada"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="owner__location">
                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359630.09737905!2d-113.7240346513082!3d36.2460939887435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1673025694244!5m2!1sen!2s"
                    allowfullscreen=""
                    loading="lazy"
                    title='property-map'
                  ></iframe> */}
                {/* <iframe src="https://maps.google.com/maps?q=%27+<?=g('db.admin.address')?>;+'&output=embed" width="100%" height="490" frameborder="0" style="border:0;" allowfullscreen=""></iframe> */}
                  <iframe
                    src={"https://maps.google.com/maps?q=%27+"+ getPro.link +";+'&output=embed"}
                    allowfullscreen=""
                    loading="lazy"
                    title='property-map'
                  ></iframe>
                </div>
                <div className="owner__others">
                  <div className="price">${getPro.price}</div>
                  <Link to="/property-document" className="themeBtn">
                    Property Documents
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default Property;
