import React from "react";
import { Link } from "react-router-dom";
// import Header from './Include/Header';

import "./Include/css/Main.css";
import "./Include/css/Responsive.css";
import "./Include/css/Overall.css";

import logo from "./Images/logo.png";
import finger_print from "./Images/finger-print-img.png"

function Main() {
    const [modalShow, setModalShow] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(localStorage.getItem("isLoggedIn"));
    
    const openFingerPrint = () =>{
        setModalShow(!modalShow)
        setIsLoggedIn(isLoggedIn)
    }
    return (

        <main className="main-sectionWrapper">
            <div id="main-section" className="primary-scroll">
                {/* <Header /> */}

                <div className={modalShow ? "custom-modal open": "custom-modal" }>
                    <div className="custom-modal-body text-center">
                    <button onClick={() => openFingerPrint()} type="button" className="custom-modal-body__close">
                        <span>
                        {" "}
                        <i className="bx bx-x"></i>
                        </span>
                    </button>
                    <div className="title mb-0">This Functionality is in Process</div>
                    </div>
                </div>

                <div className=" home">
                    <div className="container ">
                        <div id="particles-js" className="particles"></div>

                            <Link onPress={() => Link('/')} className="home__logo">
                                <img src={logo} alt='img-here' className='imgFluid' loading='lazy' />
                            </Link>
                            
                            {!localStorage.getItem("isLoggedIn") ?
                            <div className="wrapper">
                                <Link to='/sign-In' state={{ role: "1" }} className="themeBtn themeBtn--full" >Seller</Link>
                                <Link to='/sign-In' state={{ role: "2" }} className="themeBtn themeBtn--full themeBtn--secondary" >Buyer</Link>
                                <Link to='/sign-In' state={{ role: "3" }} className="themeBtn themeBtn--full themeBtn--lender" >Lender</Link>
                            </div>
                            :
                            <div className="wrapper">
                                {
                                    <Link to="/welcome" state={{ role: isLoggedIn.role_id }} className="themeBtn themeBtn--full">LogIn</Link>
                                }
                            </div>
                            }

                        <Link onClick={() => openFingerPrint()} className="finger-print">
                            <img src={finger_print} alt='img-here' className='imgFluid finger-print__img' loading='lazy' />
                            <div className="finger-print__title">Use fingerprint instead?</div>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    
  );
}
export default Main;
