import React, { useState, useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";

import Header from "../Include/Header";

function MyDocuments() {
    const [imgpath1, setImgpath1] = useState('');
    const [imgpath2, setImgpath2] = useState('');
    const [imgpath3, setImgpath3] = useState('');
    const [imgpath4, setImgpath4] = useState('');
    const [msg, setMsg] = useState('');

    const [document, setDocument] = useState([]);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [boolo, setBool] = useState(true);
    const [videoPreviews, setVideoPreviews] = useState([]);
    const [type, setType] = useState('');
    const history = useNavigate();
    
    useEffect(() => {
        if(boolo === true){
            setBool(false);
            return get_start();
        }
    });
    
    function get_start(){
        console.log(localStorage.getItem("getseller"))
        if(userData.role_id ==3){
            let result = axios
            .get("https://demo-appprojects.com/sigma-bank/api/get-buyer-document", {
                params: {
                    user_id : localStorage.getItem("getseller"),
                }

            })
            .then((response) => {
                console.log(response.data)
                if (response.data.status === 200) {
                    setDocument(response.data.properties);
                } 
            })
            .catch((error) => {
                // console.log(error);
                console.log(result);
            });
           
        }else{
            let result = axios
                .get("https://demo-appprojects.com/sigma-bank/api/get-buyer-document", {
                params: {
                    user_id :userData.id,
                }
                
            })
            .then((response) => {
               
                if (response.data.status === 200) {
                    setDocument(response.data.properties);
                } 
            })
            .catch((error) => {
                console.log(error);
                console.log(result);
            });
            
        }
    }

    const uploadImages = () =>{
        if(imgpath1 === ''||imgpath1 === null){
            setMsg("Select Real Estate Purchase Agreement Document")
        }else if(imgpath2 === ''||imgpath2 === null){
            setMsg("Select Property Appraisal Document")
        }else if(imgpath3 === ''||imgpath3 === null){
            setMsg("Select Preliminary Title Report Document")
        }else if(imgpath4 === ''||imgpath4 === null){
            setMsg("Select Pest Inspection and Clearance Document")
        }
        const formData = new FormData()
        formData.append("type", type);
        formData.append("user_id", userData.id);

        formData.append("img_path1", imgpath1);
        formData.append("img_path2", imgpath2);
        formData.append("img_path3", imgpath3);
        formData.append("img_path4", imgpath4);

        let result = axios.post("https://demo-appprojects.com/sigma-bank/api/add-saller-document", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.data.status === 200) {
                history("/all-properties")
                return get_start()
            }
        })
        .catch((error) => {
            console.log(error);
            console.log(result);
        });
        
    }

    const deleteFile = (e) =>{
        let result = axios.get("https://demo-appprojects.com/sigma-bank/api/delete-buyer-document", {
            params: {
                doc_id: e.target.attributes[1].value,
            },
        }).then((response) => {
            if (response.data.status === 200) {
                history("/all-properties")
                return get_start()
            }
        })
        .catch((error) => {
            console.log(error);
            console.log(result);
        });
        
    }

    const changeStatus = (ev) => {

        let result = axios.get("https://demo-appprojects.com/sigma-bank/api/status-buyer-request", {
            params: {
                request_id: localStorage.getItem("getProperty"),
                status : ev.target.dataset.value,
            },
        }).then((response) => {
            if (response.data.status === 200) {
                history("/all-properties")
                return get_start()
            }
            console.log(response)
        })
        .catch((error) => {
            console.log(error);
            console.log(result);
        });
        
    }

    const handleFileSelect1 = (event) => {
        setImgpath1(event.target.files[0])
        setVideoPreviews(URL.createObjectURL(event.target.files[0]));
        setType(event.target.files[0].type.substring(0, 5))
    }

    const handleFileSelect2 = (event) => {
        setImgpath2(event.target.files[0])
        setVideoPreviews(URL.createObjectURL(event.target.files[0]));
        setType(event.target.files[0].type.substring(0, 5))
    }

    const handleFileSelect3 = (event) => {
        setImgpath3(event.target.files[0])
        setVideoPreviews(URL.createObjectURL(event.target.files[0]));
        setType(event.target.files[0].type.substring(0, 5))
    }

    const handleFileSelect4 = (event) => {
        setImgpath4(event.target.files[0])
        setVideoPreviews(URL.createObjectURL(event.target.files[0]));
        setType(event.target.files[0].type.substring(0, 5))
    }
    
  return (
    <>
      <main className="main-sectionWrapper">
        <div id="main-section" className="primary-scroll">
          <Header />
            <div className="page-header">
                <div className="page-header__title">
                    <Link to="/welcome"><i className='bx bx-chevron-left'></i></Link>
                    Welcome
                </div>
            </div>

            <div className='property'>
                <div className="container">
                    <div className="property-require">
                        {userData.role_id===2 || userData.role_id==='2' ?
                        <>
                        <div className="property-require__title">Upload images or 3d Video MP4</div>
                        <ul>
                            <li> <i className='bx bx-check'></i>Ads with picture get 5x more views & leads</li>
                            <li> <i className='bx bx-check'></i>Upload good quality pictures with properListing</li>
                            <li> <i className='bx bx-check'></i>Cover all areas of your property</li>
                        </ul>
                        </>
                        :
                        <Link to={'/property'} className="themeBtn themeBtn--full">View Property</Link>
                        }
                        <div className="property-require__title">Uploaded Property Document</div>
                        <div className="property-require">
                            <ul>

                                {document.map((item, index) => (
                                    <li key={index}> 
                                        {item.id}
                                        {(userData.role_id===2||userData.role_id==='2')&&
                                        <Link onClick={deleteFile} data-id={item.id} className="delete--docx" ><i className='bx bx-trash' data-id={item.id}></i></Link>
                                        }
                                         
                                            <a href={'https://demo-appprojects.com/sigma-bank/public/'+item.img_path} download target="_blank" rel="noreferrer" className="preview" > 
                                                Download {item.type} 
                                            </a>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {(userData.role_id===2||userData.role_id==='2')?
                            <form action="#">
                                <ul className="property-documents">
                                    <li className="property-documents__single">
                                        <div className="title">Real Estate Purchase Agreement</div>
                                        <div className="document-upload">
                                            <div className="document-upload___title"><i className='bx bxs-file-plus'></i>
                                                <span>
                                                    CNIC
                                                </span>
                                            </div>
                                            <label className="themeBtn" for="doc1">
                                                <input type="file" id="doc1" className="upload" onChange={handleFileSelect1} />upload</label>
                                        </div>
                                    </li>
                                    <li className="property-documents__single">
                                        <div className="title">Property Appraisal</div>
                                        <div className="document-upload">
                                            <div className="document-upload___title"><i className='bx bxs-file-plus'></i>
                                                <span>
                                                    Passport Size Photo
                                                </span>
                                            </div>
                                            <label className="themeBtn" for="doc2">
                                                <input type="file" id="doc2" className="upload" onChange={handleFileSelect2} />upload</label>
                                        </div>
                                    </li>
                                    <li className="property-documents__single">
                                        <div className="title">Preliminary Title Report</div>
                                        <div className="document-upload">
                                            <div className="document-upload___title"><i className='bx bxs-file-plus'></i>
                                                <span>
                                                    Bank Statement
                                                </span>
                                            </div>
                                            <label className="themeBtn" for="doc3">
                                                <input type="file" id="doc3" className="upload" onChange={handleFileSelect3} />upload</label>
                                        </div>
                                    </li>
                                    <li className="property-documents__single">
                                        <div className="title">Pest Inspection and Clearance (If VA loan)</div>
                                        <div className="document-upload">
                                            <div className="document-upload___title upload"><i className='bx bxs-file-plus'></i>
                                                <span>
                                                    Educational Document
                                                </span>
                                            </div>
                                            <label className="themeBtn" for="doc4">
                                                <input type="file" id="doc4" className="upload" onChange={handleFileSelect4} />upload</label>
                                        </div>
                                    </li>
                                    <li className="property-documents__single mt-2">
                                        <span className="text-danger">{msg}</span>
                                        <Link onClick={uploadImages} className="themeBtn themeBtn--full">Publish </Link>
                                    </li>
                                </ul>
                                {videoPreviews && (
                                <>
                                    <div className="img--perview" >
                                        {type==='video' && 
                                           ( <video className="preview" controls  >
                                                <source src={videoPreviews} type="video/mp4"/>
                                            </video>)
                                        }
                                        {type==='image' && 
                                            (<img className="preview" src={videoPreviews} alt={"img"}  />)
                                        }
                                        {type==='appli' && 
                                            (<a href={videoPreviews} download target="_blank" rel="noreferrer" className="preview" > 
                                                Document
                                            </a>)
                                        }
                                    </div>
                                </>
                                )}
                            </form>
                        :
                        <>
                        <Link onClick={changeStatus} data-value={1} className="themeBtn themeBtn--full">Approved</Link>
                        <br />
                        <Link onClick={changeStatus} data-value={2} className="themeBtn themeBtn--full">Rejected</Link>
                        </>
                        }
                        
                            {/* {document.map((item, index) => (
                                <div className="img--perview" key={index}>
                                    {item.type==='video' && 
                                        ( <video className="preview" width="750" height="500" controls  >
                                            <source src={'https://demo-appprojects.com/sigma-bank/public/'+item.img_path} type="video/mp4"/>
                                        </video>)
                                    }
                                    {item.type==='image' && 
                                        (<img className="preview" src={'https://demo-appprojects.com/sigma-bank/public/'+item.img_path} alt={"image"}  />)
                                    }
                                    {item.type==='appli' && 
                                        (<a href={'https://demo-appprojects.com/sigma-bank/public/'+item.img_path} download target="_blank" rel="noreferrer" className="preview" > 
                                            Document
                                        </a>)
                                    }
                                </div>
                            ))} */}
                        
                    </div>
                </div>
            </div>
        </div>
      </main>
    </>
  );
}
export default MyDocuments;
