import React, { useEffect, useState } from "react";
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";

import Header from "../Include/Header";

function PropertyDocument() {
    const [imgpath, setImgpath] = React.useState('');
    const [document, setDocument] = React.useState([]);
    const [bool, setBool] = React.useState(true);
    const [errMsg, setErrMsg] = useState(false);
    const [is_active, setIsActive] = useState('');
    const [userData, setUserData] = React.useState(JSON.parse(localStorage.getItem("userData")));
    const history = useNavigate();

    useEffect(() => {
        if(bool){
            let title = localStorage.getItem("getProperty");
            let result = axios
            .get("https://demo-appprojects.com/sigma-bank/api/get-property-document", {
                params: {
                    title: title,
                },
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setBool(false)
                    setIsActive(response.data.is_active)
                    return setDocument(response.data.properties);
                } 
            })
            .catch((error) => {
                console.log(error);
                setUserData(userData)
            });
            console.log(result)
        }

    });

    const add_property_document = () =>{
        let property = localStorage.getItem("getProperty")
        const formData = new FormData()
        formData.append("property_id", property);
        formData.append("img_path", imgpath);
        formData.append("user_id", userData.id);
        let result = axios.post("https://demo-appprojects.com/sigma-bank/api/add-propety-document", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            if (response.data.status === 200) {
                history('/property');
            }
        })
        .catch((error) => {
            console.log(error);
        });
        console.log(result)
    }

    const handleFileSelect = (event) => {
        setImgpath(event.target.files[0])
    }

    function approved_prop(ev){
        let title = localStorage.getItem("getProperty");
        let result = axios
            .get("https://demo-appprojects.com/sigma-bank/api/approved-prop",{
            params: {
                pro_id : title,
                status : ev.target.dataset.value
              }
            })
            .then((response) => {
              if (response.data.status === 200) {
                history("/all-properties");
              }
            })
            .catch((error) => {
              console.log(error);
            });
          console.log(result)
    }

    const sendRequest = () => {
        let title = localStorage.getItem("getProperty");
        let result = axios
            .get("https://demo-appprojects.com/sigma-bank/api/buyer-send-request",{
            params: {
                pro_id : title,
                user_id : userData.id
              }
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setErrMsg(response.data.message)
                    setTimeout(() => {
                        setErrMsg('')
                        history("/welcome");
                    }, 2000);
                }
                if (response.data.status === 500) {
                    setErrMsg(response.data.message)
                    setTimeout(() => {
                        setErrMsg('')
                        history("/my-documents");
                    }, 2000);
                }
            })
            .catch((error) => {
              console.log(error);
            });
          console.log(result)
    }
    
  return (
    <>
      <main className="main-sectionWrapper">
        <div id="main-section" className="primary-scroll">
          <Header />
            <div className="page-header">
                <div className="page-header__title">
                    <Link to="/property"><i className='bx bx-chevron-left'></i></Link>
                    Property
                </div>
            </div>

            <div className='property'>
                <div className="container">
                    <div className="property-require">
                        <span className="text-danger">{errMsg}</span>
                        <div className="property-require__title">Upload images or 3d Video</div>
                        <ul>
                            <li> <i className='bx bx-check'></i>Ads with picture get 5x more views & leads</li>
                            <li> <i className='bx bx-check'></i>Upload good quality pictures with properListing</li>
                            <li> <i className='bx bx-check'></i>Cover all areas of your property</li>
                        </ul>
                        <div className="property-require__title">Uploaded Property Document</div>
                        <ul>
                            {document.map((item, index) => (
                                <li key={index}> 
                                    
                                    <i className='bx bx-download'></i>
                                    <a href={'https://demo-appprojects.com/sigma-bank/public/'+item.img_path} download target="_blank" rel="noreferrer">
                                       {item.doc_name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        {userData.role_id===3||userData.role_id==='3' ?
                        <>
                        <p>Current Status : {is_active==0&&<b>Pending</b>}{is_active==1&&<b>Approved</b>}{is_active==2&&<b>Rejected</b>}</p>
                        <Link onClick={() => approved_prop()} data-value={1} className="themeBtn themeBtn--full">Approved </Link>
                        <br />
                        <Link onClick={() => approved_prop()} data-value={2} className="themeBtn themeBtn--full">Rejected </Link>
                        </>
                        :
                        userData.role_id==='2'||userData.role_id===2 ?
                            <Link onClick={sendRequest} className="themeBtn themeBtn--full">Send Request</Link>
                        :
                        <form action="#">
                            <div className="property-upload">
                                <label for="gallery" className="themeBtn">
                                    <i className='bx bxs-image'></i>From Gallery
                                    <input type="file" id="gallery" onChange={handleFileSelect} />
                                </label>
                                <label for="camera" className="themeBtn themeBtn--outline">
                                    <i className='bx bxs-camera'></i>From Camera
                                    <input type="file" id="camera" onChange={handleFileSelect} />
                                </label>
                            </div>
                            <div className="property-upload">
                                <Link to="/submit-property-documents" className="themeBtn">
                                    <i className='bx bx-file'></i>Submit Documents
                                </Link>
                            </div>
                            <Link onClick={add_property_document} className="themeBtn themeBtn--full">Pubslish </Link>
                        </form>
                        
                        }
                    </div>
                </div>
            </div>
        </div>
      </main>
    </>
  );
}
export default PropertyDocument;
