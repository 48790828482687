import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import Header from "../Include/Header";

import bottom_bg from "../Images/bottom-bg-img-2.png";
import property_img from "../Images/No-Image-Placeholder.svg.png";

function AllProperties() {
  const [add_p, setAdd] = React.useState('all');
  const [property, setProperty] = React.useState([]);
  const [newpro, setNewpro] = React.useState(true);
  const user = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (newpro === true) {
      let result = axios
        .get("https://demo-appprojects.com/sigma-bank/api/get-property", {
          params: {
            user_id: user.id,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setProperty(response.data.properties);
            setAdd('all')
          }
          setNewpro(false);
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(result);
    }
  });

  const history = useNavigate();
  function propertyDetail(ev) {
    let title = ev.target.dataset.value;
    localStorage.setItem("getProperty", title);
    history("/property");
  }

  return (
    <>

      <main className="main-sectionWrapper">
        <div id="main-section" className="primary-scroll">
          <Header />
          <img
            src={bottom_bg}
            alt="img-here"
            className="imgFluid bottom-bg"
            loading="lazy"
          />

          <div className="page-header">
            <div className="page-header__title">
              <Link to="/welcome">
                <i className="bx bx-chevron-left"></i>
              </Link>
              Welcome
            </div>
            <div className="page-header__title"></div>
            {user.role_id === "1" || user.role_id === 1 ? (
              <Link to="/add-property" className="icon">
                <i className="bx bx-plus-circle"></i>
              </Link>
            ) : (
              ""
            )}
          </div>
          {user.role_id === 3 &&
          <ul className="properties-listing">
            <li>
              <Link onClick={() => setAdd("all")} className="themeBtn">
                All properties
              </Link>
            </li>
            <li>
              <Link onClick={() => setAdd(1)} className="themeBtn">
                Approved Properties
              </Link>
            </li>
            <li>
              <Link onClick={() => setAdd(0)} className="themeBtn">
                Pending Properties
              </Link>
            </li>
            <li>
              <Link onClick={() => setAdd(2)} className="themeBtn">
                Rejected Properties
              </Link>
            </li>
          </ul>
          }


          <div className="currency-updatesWrapper">
            <div className="container">
              <ul className="currency-updates">
                {property.map((item, index) => (
                  add_p==='all'&&
                  <li className="currency-updates__single" key={index}>
                    <div className="coin">
                      <div className="coin__img">
                        {item.image === null ? (
                          <img
                            src={property_img}
                            alt="img-here"
                            className="imgFluid"
                            loading="lazy"
                            data-value={item.id}
                            onClick={propertyDetail}
                          />
                        ) : (
                          <img
                            src={
                              "https://demo-appprojects.com/sigma-bank/public/" +
                              item.image
                            }
                            alt="img-here"
                            className="imgFluid"
                            loading="lazy"
                            data-value={item.id}
                            onClick={propertyDetail}
                          />
                        )}
                      </div>
                      <div className="coin-info">
                        <div
                          className="coin-info__shortname"
                          data-value={item.id}
                          onClick={propertyDetail}
                        >
                          {item.title} 
                        </div>
                        <div className="coin-info__fullname">
                          {item.fullname}
                        </div>
                        <div className="coin-info__fullname">
                          <b>
                          {item.is_active===0&&' Awaring for Approval'}
                          {item.is_active===1&&'Approved'}
                          {item.is_active===2&&'Rejected'}
                          {item.is_active===3&&'Pending'}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="coin-chart">
                      <canvas id="myChart1" width="80" height="40"></canvas>
                    </div>
                    <div className="uptdated">
                      <div className="uptdated__price">${item.price}</div>
                      <div className="uptdated__index up">+{item.rating}%</div>
                    </div>
                  </li>

                ))}
                {property.map((item, index) => (
                  add_p===item.is_active&&
                  <li className="currency-updates__single" key={index}>
                    <div className="coin">
                      <div className="coin__img">
                        {item.image === null ? (
                          <img
                            src={property_img}
                            alt="img-here"
                            className="imgFluid"
                            loading="lazy"
                            data-value={item.id}
                            onClick={propertyDetail}
                          />
                        ) : (
                          <img
                            src={
                              "https://demo-appprojects.com/sigma-bank/public/" +
                              item.image
                            }
                            alt="img-here"
                            className="imgFluid"
                            loading="lazy"
                            data-value={item.id}
                            onClick={propertyDetail}
                          />
                        )}
                      </div>
                      <div className="coin-info">
                        <div
                          className="coin-info__shortname"
                          data-value={item.id}
                          onClick={propertyDetail}
                        >
                          {item.title}
                        </div>
                        <div className="coin-info__fullname">
                          {item.fullname}
                        </div>
                        <div className="coin-info__fullname">
                        <b>
                          {item.is_active===0&&'Pending Awaring for Approval'}
                          {item.is_active===1&&'Approved'}
                          {item.is_active===2&&'Rejected'}
                        </b>
                        </div>
                      </div>
                    </div>
                    <div className="coin-chart">
                      <canvas id="myChart1" width="80" height="40"></canvas>
                    </div>
                    <div className="uptdated">
                      <div className="uptdated__price">${item.price}</div>
                      <div className="uptdated__index up">+{item.rating}%</div>
                    </div>
                  </li>

                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
      {/* )} */}
    </>
  );
}
export default AllProperties;
