import React from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

import facebook from "../Images/facebook.png"
import google from "../Images/google.png"
import fingerprint from "../Images/finger-print-img.png"
import bottom_bg from "../Images/bottom-bg-img.png"

// import Header from "../Include/Header";

function SignUp(props) {
    const history = useNavigate();
    const [fullname, setFullname] = React.useState('');
    const [email, setEmail] = React.useState(false);
    const [password, setPassword] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState(false);
    const [msg, setMsg] = React.useState(false);

    const [remove_pass1, setRemove_pass1] = React.useState(true);
    const [remove_pass2, setRemove_pass2] = React.useState(true);
    
    const [modalShow, setModalShow] = React.useState(false);

    const openFingerPrint = () =>{
        setModalShow(!modalShow)
    }
    const onSubmitHandler = (e) => {
        let role = localStorage.getItem("role");
        let item = {email, password, confirmPassword, fullname, role };
        e.preventDefault();
        const result = axios.post("https://demo-appprojects.com/sigma-bank/api/sign-up", item)
        .then((response) => {
            if (response.data.status === 200) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userData", JSON.stringify(response.data.data));
                setMsg(response.data.message)
                setRedirect(true)
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                history("/acountVarify")
                return <Navigate to="/acountVarify" />;
            } 
            if (response.data.status === "failed") {
                setMsg(response.data.message)
                // setTimeout(() => {
                //     setMsg("")
                // }, 4000);
            }
        })
        .catch((error) => {
            setMsg(error.message)
        }); 
        if(result)
        {
            console.log(result)
        }
    };
    if (redirect) {
        // return <Navigate to="/acountVarify" />;
    }

    return (

        <>
        {/* {modalShow && <Header />} */}
        <main className="main-sectionWrapper">
            <div id="main-section" className="primary-scroll">
            
            <div className={modalShow ? "custom-modal open": "custom-modal" }>
                <div className="custom-modal-body text-center">
                <button onClick={() => openFingerPrint()} type="button" className="custom-modal-body__close">
                    <span>
                    {" "}
                    <i className="bx bx-x"></i>
                    </span>
                </button>
                <div className="title mb-0">This Functionality is in Process</div>
                </div>
            </div>

            <img
                src={bottom_bg}
                alt="img_here"
                className="imgFluid bottom-bg"
                loading="lazy"
            />
            <div className="auth primary-scroll">
                <div className="container">
                <ul className="auth-tabs">
                    <li className="auth-tabs__single">
                    <Link to="/sign-In" state={{ role: localStorage.getItem("role") }} >Sign In</Link>
                    </li>
                    <li className="auth-tabs__single">
                    <Link className="active">
                        Sign Up
                    </Link>
                    </li>
                </ul>
                <div className="auth__title">Sign up </div>
                <form action="#" className="auth-form">
                    <div className="auth-form__fields">
                    <label>
                        Full Name
                        <span>Register with mobile</span>
                    </label>
                    <input
                        type="text"
                        name="fullname"
                        placeholder="Please enter Full Name"
                        onChange={ (e) => setFullname(e.target.value) }
                    />
                    </div>
                    <div className="auth-form__fields">
                    <label>
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Please enter email"
                        onChange={ (e) => setEmail(e.target.value) }
                    />
                    </div>
                    <div className="auth-form__fields">
                    <label>Password</label>
                    <div className="passwordWrapper">
                        <input
                        type={remove_pass1?'password':'text'} 
                        name="password"
                        className="password-field"
                        placeholder="Please enter Password"
                        onChange={ (e) => setPassword(e.target.value) } 
                        />
                        <i className="icon toggle-password bx bxs-show" onClick={() => setRemove_pass1(!remove_pass1)}></i>
                    </div>
                    </div>
                    <div className="auth-form__fields">
                    <label>Confirm Password</label>
                    <div className="passwordWrapper">
                        <input
                        type={remove_pass2?'password':'text'}
                        name="password"
                        className="password-field"
                        placeholder="Confirm Password"
                        onChange={ (e) => setConfirmPassword(e.target.value) }
                        />
                        <i className="icon toggle-password bx bxs-show" onClick={() => setRemove_pass2(!remove_pass2)}></i>
                    </div>
                    </div>
                    <span className="text-danger">{msg}</span>
                    <div className="auth-form__fields">
                    
                    <Link onClick={onSubmitHandler} className="themeBtn themeBtn--full">
                        Sign Up
                    </Link>
                    
                    </div>
                </form>
                <div className="auth-other">
                    <div className="auth-other__title">Or Sign up with</div>
                    <ul className="auth-other__options">
                    <li>
                        <Link href="https://www.facebook.com/">
                        <img
                            src={facebook}
                            alt="img_here"
                            className="imgFluid"
                            loading="lazy"
                        />
                        Facebook
                        </Link>
                    </li>
                    <li>
                        <Link href="https://www.google.com/">
                        <img
                            src={google}
                            alt="img_here"
                            className="imgFluid"
                            loading="lazy"
                        />
                        Google
                        </Link>
                    </li>
                    </ul>
                </div>
                <Link
                    onClick={() => openFingerPrint()}
                    className="finger-print finger-print--static"
                >
                    <img
                    src={fingerprint}
                    alt="img_here"
                    className="imgFluid finger-print__img"
                    loading="lazy"
                    />
                    <div className="finger-print__title">
                    Use fingerprint instead?
                    </div>
                </Link>
                </div>
            </div>
            </div>
        </main>
        </>
    );
}
export default SignUp;
